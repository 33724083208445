.ac-all-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
  padding-bottom: 100px;
}

.ac-all-title {
  font-weight: 500;
  font-size: 40px;
  color: white;
}

.ac-why-admin-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.ac-why-admin-image-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.ac-why-admin-confetti {
  width: 23px;
  height: 36px;
}

.ac-why-admin-badge {
  width: 145px;
  height: 85px;
  margin: 0px 12px;
}

.ac-why-admin-title {
  font-weight: 400;
  font-size: 16px;
  color: white;
  margin-top: 12px;
}

.ac-why-admin-question {
  display: flex;
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  color: #426bfd;
  width: 100%;
  position: relative;
}

.ac-why-admin-question img {
  width: 24px;
  height: 24px;
  margin-left: 6px;
}

.ac-why-admin-tip-container {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #313141;
  border-radius: 16px;
  padding: 16px 60px;
  display: flex;
  width: 500px;
  backdrop-filter: blur(20px);
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 200;
  top: 30px;
}

.ac-why-admin-tip-text {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: white;
}

.ac-all-link-connect {
  color: #426bfd !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
}

.ac-all-subtitle {
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  color: white;
  margin-bottom: 20px;
}

.need_help_ac {
  color: #426bfd !important;
  font-weight: 500 !important;
  text-decoration: underline !important;
}

.ac-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.ac-inner-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 5vh;
}

.ac-progress-dot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #426bfd;
  border: 1px solid transparent;
}

.ac-progress-dot-inactive {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: transparent;
  border: 1px solid #84848d;
}

.ac-progress-dot-filled {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: #84848d;
  border: 1px solid #84848d;
}

.ac-progress-dot-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.ac-progress-step {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ac-progress-line {
  width: 60px;
  height: 1px;
  background-color: #426bfd;
}

.ac-progress-line-invisible {
  width: 60px;
  height: 1px;
  background-color: transparent;
}

.ac-progress-line-inactive {
  width: 60px;
  height: 1px;
  background-color: #84848d;
}

.ac-progress-title {
  font-weight: 500;
  font-size: 18px;
  color: white;
  margin-bottom: 10px;
}

.ac-step-image-container {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid #313141;
  border-radius: 16px;
  padding: 16px 60px;
  display: flex;
  backdrop-filter: blur(20px);
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 200;
  bottom: 20px;
  right: 0;
}

.ac-step-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.ac-steps-lock {
  width: 60px;
  height: 60px;
  position: absolute;
  left: -20px;
  top: -20px;
}

.ac-step-zero-image {
  width: 500px;
  height: auto;
}

.ac-step-one-image {
  width: 500px;
  height: auto;
}

.ac-step-two-image {
  width: 500px;
  height: auto;
}

.ac-step-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 12px;
  color: white;
}

.ac-step-image-bottom {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 20px;
}

.ac-step-add-icon {
  width: 30px;
  height: 30px;
  margin-right: 8px;
}

.ac-step-image-bottom-text-with-access::before {
  content: "Access:";
  font-weight: 300;
  font-size: 12px;
  height: 100%;
  left: 0;
  position: absolute;
  top: -16px;
  width: 100%;
}

.ac-step-image-bottom-text-with-hover::before {
  content: "Hover Over The Key";
  font-weight: 300;
  font-size: 12px;
  height: 100%;
  left: 0;
  position: absolute;
  top: -16px;
  width: max-content;
}

.ac-step-left-arrow {
  width: 30px;
  height: auto;
  margin: 0px 8px;
}

.ac-step-image-bottom-text {
  font-weight: 500;
  font-size: 12px;
  color: white;
  position: relative;
}

.ac-select-apps-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ac-select-apps-top-title {
  font-weight: 400;
  font-size: 16px;
  color: white;
}

.ac-select-apps-top-subtitle {
  font-weight: 300;
  font-size: 16px;
  color: #84848d;
}

.ac-select-apps-table {
  width: 1000px;
}

.ac-select-apps-table-container {
  display: flex;
  flex-direction: column;
  border: 1px solid #313141;
  border-radius: 6px;
  background-color: #060614;
  overflow: hidden;
  margin-top: 10px;
  min-height: 400px;
  max-height: 500px;
  overflow: scroll;
}

.ac-select-apps-table-header {
  background-color: #101022;
  display: flex;
  flex-direction: row;
  padding: 16px 30px;
}

.ac-select-apps-table-header-title {
  font-weight: 300;
  color: white;
  font-size: 16px;
  margin-left: 30px;
}

.ac-select-apps-table-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 30px;
}

.ac-select-apps-table-row-title {
  font-weight: 400;
  color: white;
  font-size: 16px;
  margin-left: 30px;
}

.ac-select-apps-table-row-app-icon {
  width: 36px;
  height: 36px;
  border-radius: 10px;
}

.ac-select-apps-table-row-app-icon-container {
  display: flex;
  align-items: center;
}

@media (max-width: 1500px) {
  .ac-step-zero-image {
    width: 805px;
    height: auto;
  }

  .ac-step-one-image {
    width: 730px;
    height: auto;
  }

  .ac-step-two-image {
    width: 869px;
    height: auto;
  }

  .ac-step-image-container {
    width: 1000px;
  }
}

@media (max-width: 1080px) {
  .ac-step-zero-image {
    width: 600px;
    height: auto;
  }

  .ac-step-one-image {
    width: 600px;
    height: auto;
  }

  .ac-step-two-image {
    width: 600px;
    height: auto;
  }

  .ac-step-image-container {
    width: 872px;
  }
}
