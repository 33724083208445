.search-container {
  margin: 20px 40px;
  width: 300px;
  height: 46px;
  padding: 0px 12px;
  border: 1px solid #313141;
  background-color: #08081a;
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-icon {
  width: 32px;
  height: 32px;
  margin-right: 4px;
}

.search-input {
  border: none;
  border-radius: 6px;
  background-color: transparent;
  font-weight: 400;
  font-family: "Poppins";
  color: white;
  width: 100%;
  padding-left: 0px;
  font-size: 16px;
}

.search-input:focus {
  border: none;
  outline: none;
}
