.se-header-container {
  display: flex;
  flex-direction: column;
}

.se-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #101022;
  padding: 12px 40px;
  height: 40px;
  justify-content: space-between;
}

.se-header-country {
  font-size: 18px;
  font-weight: 300;
  color: white;
  width: 240px;
  padding-left: 40px;
  height: 100%;
}

.se-header-title {
  font-size: 18px;
  font-weight: 300;
  color: white;
  height: 100%;
}

.se-edit-button-container {
  border: 1px solid white;
  border-radius: 8px;
  padding: 9px 15px;
  margin-left: 20px;
}

.se-edit-button-text {
  font-size: 16px;
  font-weight: 400;
  color: white;
}
