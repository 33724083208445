.settings_container {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

.dot_container {
  display: flex;
  margin-top: 25px;
  flex-direction: row;
}

.dot_style {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.dot_text {
  color: white;
  font-size: 16px;
  margin-left: 10px;
}

.active_subs_card {
  display: flex;
  flex-direction: row;
  align-self: baseline;
  border-radius: 8px;
  margin-top: 15px;
  background-color: rgba(255, 255, 255, 0.1);
}

.active_subs_card_title_container {
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
  align-items: flex-start;
}

.active_subs_card_title {
  color: white;
  font-size: 16px;
}

.active_subs_card_limit {
  color: #84848d;
  font-weight: 400;
  font-size: 14px;
}

.active_subs_card_icon {
  width: 50px;
  margin: 20px;
  height: 50px;
  object-fit: contain;
}

.plan_container {
  display: flex;
  margin-top: 40px;
  margin-left: 30px;
  height: 100%;
  width: auto;
}

.plan_container_content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  flex: 1.5;
  height: 100%;
}

.plan_span {
  color: white;
  font-size: 24px;
}

.dash_line {
  align-self: center;
  margin-top: 10px;
  margin-bottom: 15px;
  width: 95%;
  border-top-width: 2px;
  border-top-style: dashed;
  border-top-color: rgba(255, 255, 255, 0.2);

  height: 5px;
}

.selected_dot {
  width: 5px;
  height: 5px;
  border-radius: 10px;
  border-color: #426bfd;
  border-width: 4px;
  border-style: solid;
  background-color: white;
}

.plan_pack {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.discount_container {
  background-color: rgba(74, 191, 126, 0.2);
}

.discount_text {
  color: #4abf7e;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 6px;
  text-align: center;
  font-size: 12px;
  padding-top: 2px;
  padding-bottom: 2px;
  margin-left: 15px;
  border-radius: 13px;
}

.plan_pack_text {
  color: white;
  font-size: 16px;
  margin-left: 8px;
}

.plan_packages_container {
  margin-top: 12px;
  display: flex;
  align-items: center;
}

.un_selected_dot {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  border-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  margin-left: 1px;
  background-color: transparent;
}

.plan_choose_button {
  margin-top: 60px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  margin-bottom: 30px;
}

.plan_choose_text {
  color: white;
  font-weight: 500;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.red_card {
  min-width: 300px;
  flex-direction: column;
  display: flex;
  margin-left: 20px;
  padding-top: 20px;
  margin-right: 20px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 18px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.1);
  background-image: -webkit-linear-gradient(#202030, #302742, #312740);
}

.blue_card {
  flex: 1;
  min-width: 300px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  border-radius: 18px;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(255, 255, 255, 0.1);
  background-image: -webkit-linear-gradient(#212131, #272c4b, #272f4d);
  margin-right: 20px;
}

.blue_card_v2 {
  flex: 1;
  min-width: 300px;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  border-radius: 18px;
  border-width: 1px;
  border-style: solid;
  border-color: #647ed6;
  background-image: -webkit-linear-gradient(
    rgba(40, 130, 238, 0.1),
    rgba(38, 124, 229, 0.1)
  );
  margin-right: 20px;
}

.blue_card_v2_custom_pricing {
  font-size: 45px;
  color: white;
  font-weight: 600;
  line-height: 50px;
}

.blue_card_v2_desc {
  font-size: 17px;
  color: white;
}

.blue_card_v2_button {
  margin-top: 25px;
  width: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  border-radius: 8px;
  margin-bottom: 30px;
  background-color: white;
}

.blue_card_v2_button_text {
  color: black;
  font-weight: 500;
  font-size: 18px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.blue_text_gradient {
  background: -webkit-linear-gradient(0deg, #3896e4, white);
  -webkit-background-clip: text;
  font-weight: 500;
  font-size: 34px;
  -webkit-text-fill-color: transparent;
}

.red_text_gradient {
  background: -webkit-linear-gradient(0deg, #dd61a0, #d8d8d8);
  -webkit-background-clip: text;
  font-weight: 500;
  font-size: 34px;
  -webkit-text-fill-color: transparent;
}

.plan_title_container {
  display: flex;
  flex-direction: column;
}

.plan_card_desc {
  font-size: 14px;
  margin-right: 15%;
  color: #84848d;
}

.plan_bottom_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.settings_menu_container {
  display: flex;
  flex-direction: column;
  width: 400px;
  border-right-style: solid;
  border-right-color: #313141;
  border-right-width: 1px;
}

.settings_contenst_container {
  display: flex;
  flex: 4;
}

.account_settings_text {
  margin-left: 70px;
  width: 100%;
  margin-top: 30px;
  font-size: 30px;
  font-weight: 500;
  color: white;
}

.settings_menu_button_container {
  display: flex;
  margin-top: 40px;
  align-items: center;
  flex-direction: row;
}

.settings_menu_button_icon {
  width: 24px;
  margin-left: 70px;
  height: 24px;
  opacity: 0.5;
  border-radius: 8px;
}

.settings_menu_button_icon_selected {
  width: 24px;
  margin-left: 70px;
  height: 24px;
  border-radius: 8px;
}

.settings_menu_button_text {
  color: #84848d;
  font-weight: 400;
  font-size: 18px;
  margin-left: 15px;
}

.settings_menu_button_text_selected {
  color: white;
  font-weight: 400;
  font-size: 18px;
  margin-left: 15px;
}

.setting_page_container {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
}

.settings_page_title_container {
  display: flex;
  margin: 30px;
  justify-content: space-between;
  flex-direction: row;
}

.setting_page_title {
  color: white;
  font-size: 28px;
}

.settings_account_container {
  flex: 1;
  flex-direction: column;
  display: flex;
  margin-left: 30px;
  margin-right: 30px;
}

.setting_custom_title {
  color: white;
  font-size: 18px;
}

.setting_custom_desc {
  color: #84848d;
  font-size: 16px;
}

.delete_button {
  margin-left: 0;
  width: 150px;
  margin-top: 16px;
  background-color: transparent;
  border-color: #c1173c;
  border-width: 1px;
  border-style: solid;
}

.setting_page_subtitle {
  color: white;
  font-size: 16px;
}

.add_app_remove_icon_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 8px;
}

.add_app_remove_text {
  color: white;
}

.add_app_remove_icon {
  width: 35px;
  height: 35px;
  object-fit: contain;
}

.settings_account_item {
  background-color: rgba(255, 255, 255, 0.2);
  width: 400px;
  display: flex;
  margin-right: 25px;
  position: relative;
  margin-top: 25px;
  height: 85px;
  border-radius: 8px;
  align-items: center;
  flex-direction: row;
}

.store_icon_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
  margin-left: 20px;

  border-radius: 8px;
  background-color: black;
}

.settings_account_container {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.settings_account_desc {
  margin-top: -3px;
  color: #84848d;
  font-size: 14px;
}

.app_store_icon {
  width: 35px;
  padding: 8px;
  margin-left: 12px;
  border-radius: 16px;
  height: 35px;
  object-fit: contain;
}

.store_icon {
  width: 30px;
  padding: 8px;
  height: 30px;
  object-fit: contain;
}

.settings_account_title {
  color: white;
}

.settings_account_right_arrow {
  width: 20px;
  object-fit: contain;
  height: 18px;
  margin-right: 20px;
}

.settings_add_new_account_button {
  background-color: transparent;
  width: 400px;
  display: flex;
  margin-right: 25px;
  margin-top: 25px;
  height: 83px;
  border-style: dashed;
  border-color: white;
  justify-content: center;
  align-items: center;
  border-width: 1px;
  border-radius: 8px;
}

.add_icon_new_account {
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.three_dot_icon_settings {
  width: 12px;
  height: 25px;
  margin-right: 15px;
  object-fit: contain;
}

.add_icon_settings {
  width: 25px;
  height: 25px;
  margin-right: 15px;
  object-fit: contain;
}

.all_packages_text {
  font-weight: 500;
  font-size: 24px;
  color: white;
}

.all_packages_paragraph {
  font-weight: 300;
  font-size: 16px;
  color: #84848d;
}

.gradient_text_smart {
  font-weight: 600;
  font-size: 28px;
  background: -webkit-linear-gradient(
    45deg,
    #3896e4,
    #385bf5,
    #894fe5,
    #c946d9,
    #e76f86
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(255, 255, 255, 0.2);
}

.all_packages_text_container {
  background-image: url(../../assets/bg-billing.webp);
  background-size: contain;
  background-repeat: no-repeat;
}
