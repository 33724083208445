.metadata-container {
  display: flex;
  flex-direction:column;
  background-color: #08081A;
  min-height: 100vh;
  
  margin: 0px 40px;
  margin-top: 16px;
}

.metadata-input-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}