
.custom_input_tag_keyword {
  background-color: #313141;
  color: white;
  padding: 4px 20px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom_input_tag_keyword_inactive {
  background-color: #1B1B2C;
  color: #454554;
  padding: 4px 10px;
  border-radius: 8px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.custom_input_tag_keyword_div{
  position: relative;
  display: flex;
  align-items: center;
}

.custom_input_tag_keyword:hover {
  background-color: white;
  color: #313141;
  padding-left: 10px;
  padding-right: 30px;
}

.custom_input_tag_close{
  display: none;
  position: absolute;
  pointer-events: none;
  right: 10px;
  width: 12px;
  height: 12px;
}

.custom_input_tag_keyword:hover + .custom_input_tag_close {
  display: block;
}

.keywords-grid {
  display: flex;
  max-width: max-content;
  flex-wrap: wrap;
  gap: 6px;
}

.custom_tag_input_container {
  border: none;
  background-color: #1B1B2C;
  border-radius: 6px;
  font-weight: 400;
  font-family: "Poppins";
  color: white;
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px solid transparent;
}

.custom_input_tag {
  border: none;
  background-color: #1B1B2C;
  border-radius: 6px;
  font-weight: 400;
  width: 100%;
  height: 46px;
  margin-left: 12px;
  font-family: "Poppins";
  color: white;
  font-size: 16px;
}

.custom_input_tag:focus {
  border: none;
  outline: none;
}

.custom_input_tag_inactive {
  background-color: #101022;
  color: #454554;
}

.custom_tag_input_container_inactive {
  background-color: #101022;
}



.custom_input_tag_keyword_deleted {
  color: white;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.custom_input_tag_keyword_deleted:hover{
  color: white;
}

.custom_input_tag_keyword_deleted_div{
  position: relative;
  display: flex;
  border-radius: 8px;
  border: 1px dashed #313141;
  align-items: center;
  opacity: .7;
  padding: 4px 10px;
}

.custom_input_tag_keyword_deleted_div:hover{
  opacity: 1;
  border: 1px solid #313141;
}

.custom_input_tag_deleted_plus{
  width: 12px;
  height: 12px;
  margin-left: 10px;
}

.keyword_input_maxlength{
  font-size: 16px;
  color: #454554;
  font-weight: 400;
  margin-top: 4px;
}