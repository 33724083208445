.dropzone-container {
  border: 2px dashed #313141;
  background-color: #1B1B2C;
  border-radius: 6px;
  width: 100%;
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: copy;
  position: relative;
}

.dropzone-text {
  font-weight: 500;
  font-size: 16px;
  color: white;
}

.dropzone-icon {
  width: 50px;
  height: 50px;
}