.create-temp-modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.create-temp-modal-inner-container {
  display: flex;
  align-items: flex-start;
  width: 90%;
  justify-content: center;
  margin-bottom: 24px;
  flex-direction: column;
}

.create-temp-modal-input-title {
  color: white;
  font-size: 16px;
  font-weight: 400;
  margin-left: 4px;
}

.create-temp-modal-input {
  border: 1px solid transparent;
  background-color: #1b1b2c;
  border-radius: 6px;
  height: 46px;
  margin-top: 8px;
  padding-left: 12px;
  font-weight: 400;
  font-family: "Poppins";
  color: white;
  font-size: 16px;
  width: 97%;
}

.create-temp-modal-input:focus {
  border: 1px solid white;
  outline: none;
}
