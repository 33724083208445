.inapp-edit-modal-container {
  width: 100%;
  min-height: 500px;
  height: 100%;
}

.inapp-edit-modal-inner-container {
  width: 100%;
  min-height: 500px;
  height: 100%;
}

.inapp-edit-modal_item_bottom_card_flag {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  object-fit: cover;
  margin-left: 40px;
}

.inapp-edit-modal-table-row-item-price {
  background-color: #1b1b2c;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 14px;
  width: 160px;
}

.inapp-edit-modal-table-row-item-dropline {
  width: 20px;
  height: 10px;
}

.inapp-edit-modal-table-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 40px;
}
