.smart-editor-container {
  display: flex;
  flex-direction:column;
  background-color: #08081A;
  padding-bottom: 32px;
  width: 100%;
}

/* .smart-editor-rows-container {
  max-height: 70vh;
  overflow: scroll;
} */