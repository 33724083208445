.default_input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px ;
  position: relative;
  width: 100%;
}

.default_input_title {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.default_input_input {
  border: 1px solid transparent;
  background-color: #1B1B2C;
  border-radius: 6px;
  height: 46px;
  font-weight: 400;
  font-family: "Poppins";
  color: white;
  padding-left: 12px;
  font-size: 16px;
  margin-top: 10px;
}

.default_input_input:focus {
  border: 1px solid white;
  outline: none;
}