.header_container {
  width: 100%;
  background-color: #101022;
  height: 80px;
  min-height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-bottom-color: #313141;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.secret_list_profile_circle {
  width: 40px;
  height: 40px;
}

.logo {
  height: 60px;
  width: auto;
  padding-left: 40px;
}

.contents {
  display: flex;
  flex: 1;
  align-self: stretch;
  margin-left: 60px;
  flex-direction: row;
  align-items: flex-end;
}

.header_button {
  display: flex;
  cursor: pointer;
  text-align: center;
  height: 46px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 30px;
  border-bottom-color: #426bfd;
  border-bottom-width: 3px;
  border-bottom-style: solid;
}

.header_button_unselected {
  display: flex;
  text-align: center;
  height: 46px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 30px;
  border-bottom-color: transparent;
  border-bottom-width: 3px;
  position: relative;
  border-bottom-style: solid;
}

.header_button_secret {
  display: flex;
  text-align: center;
  height: 46px;
  padding-left: 8px;
  padding-right: 8px;
  margin-right: 30px;
  border-bottom-color: #426bfd;
  border-bottom-width: 3px;
  position: relative;
  align-self: center;
  align-items: center;
}

.header_button_secret_arrow {
  width: 20px;
  height: 7px;
  margin-left: 8px;
  object-fit: contain;
}

.header_button_text {
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.header_button_text_unselect {
  color: #84848d;
  font-size: 18px;
  font-weight: 500;
}

.header_button_text_title {
  color: white;
  font-size: 16px;
  font-weight: 500;
  text-align: right;
}

.header_button_text_subtitle {
  color: white;
  font-size: 14px;
  text-align: right;
  font-weight: 400;
}

.settings_settings_text {
  color: white;
}

.settings_settings_icon {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.settings_settings_text_container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.secret_list_secret_item {
  display: flex;
  padding-bottom: 16px;
  text-wrap: wrap;
  text-align: left;
  flex-direction: row;
  text-decoration: none;
  align-self: stretch;
}

.secret_list_secret_item_text {
  color: white;
  margin-right: 8px;
  margin-left: 10px;
  font-size: 14px;
}

.settings_email_text {
  color: white;
  opacity: 0.5;
}

.add_new_store_connection_button {
  display: flex;
}

.account_modal {
  background-color: rgba(255, 255, 255, 0.1);
  width: 300px;
  overflow: auto;
  position: absolute;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1.5px solid #313141;
  z-index: 999;
  backdrop-filter: blur(10px);
}

.free_trial_timer_icon {
  width: 28px;
  height: 28px;
  margin-right: 6px;
}

.free_trial_timer_text {
  font-weight: 400;
  color: white;
  font-size: 16px;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes AnimationName {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.heroTryButtonOuter {
  background: linear-gradient(
    90deg,
    #38c0d8,
    #385bf5,
    #894fe5,
    #c946d9,
    #f78559
  );
  padding: 2px;
  border-radius: 14px;
  background-size: 200% 200%;
  margin-right: 50px;
  margin-left: 12px;
  -webkit-animation: AnimationName 2s ease infinite;
  -moz-animation: AnimationName 2s ease infinite;
  animation: AnimationName 2s ease infinite;
}

.heroTryButton {
  background-color: white;
  border: 6px solid #08081a;
  border-radius: 12px;
  padding: 6px 10px;
  color: #08081a;
  font-size: 14px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
}

.heroTryButton:hover {
  background-color: #d9d9d9;
}
