.se-input-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  position: relative;
  z-index: 0;
}

.smart_input_input {
  border: 1px solid transparent;
  background-color: #1B1B2C;
  border-radius: 6px;
  height: 44px;
  font-weight: 400;
  font-family: "Poppins";
  color: white;
  padding-left: 12px;
  font-size: 16px;
}

.smart_input_input:focus {
  border: 1px solid white;
  outline: none;
}

.smart_textarea {
  padding-top: 10px;
  resize: none;
  height: 150px;
}

.se-input-maxlength {
  position: absolute;
  font-size: 16px;
  top: 13px;
  margin-right: 16px;
  color: #84848D;
  font-weight: 400;
  align-self: flex-end;
}

.se-input-container-inactive {
  color: #454554;
}

.smart_input_input_inactive {
  background-color: #101022;
  color: #454554;
}