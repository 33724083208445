.table-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #101022;
  padding: 12px 0px;
  height: 40px;
  justify-content: space-between;
}

.table-header-item-title {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  font-size: 18px;
  font-weight: 300;
  color: white;
}

.table-header-right-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
