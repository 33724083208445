.pricing-edit-modal-container {
  width: 100%;
  min-height: 500px;
  height: 100%;
}

.pricing-edit-modal-inner-container {
  width: 100%;
  min-height: 500px;
  height: 100%;
}

.pricing-edit-modal_item_bottom_card_flag {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  object-fit: cover;
  margin-left: 40px;
}

.price-template-modal-table-row-item-price {
  background-color: #1b1b2c;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 160px;
  padding: 8px 10px;
}

.pricing-template-modal-table-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 40px;
  padding: 20px 0px;
}

.price-template-modal-preserve-text {
  font-size: 18px;
  color: white;
  font-weight: 400;
  display: flex;
  align-items: center;
}

.price-template-modal-preserve-text img {
  width: 26px;
  height: 26px;
  margin-left: 12px;
}

.price-template-modal-header-right {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.price-template-modal-preserve-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.price-template-modal-preserve-tick-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #426bfd;
  width: 24px;
  height: 24px;
  border: 1px solid #353544;
  border-radius: 4px;
  margin-right: 12px;
}

.price-template-modal-preserve-tick-container img {
  width: 14px;
  height: auto;
}

.price-template-modal-tip-container {
  background-color: rgba(0, 0, 0, 1);
  border: 1px solid #313141;
  border-radius: 16px;
  padding: 16px 60px;
  display: flex;
  width: 500px;
  flex-direction: column;
  align-items: center;
  position: absolute;
  z-index: 600;
  top: 30px;
  right: 10px;
}

.price-template-modal-tip-text {
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: white;
}
