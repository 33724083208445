.rt_top_button_container {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  align-self: auto;
  padding-left: 12px;
  padding-top: 8px;
  margin-right: 20px;
  padding-bottom: 8px;
  padding-right: 12px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  position: relative;
}
