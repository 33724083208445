
.create_new_version {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  margin-left:20px;
  margin-right: 20px;
  align-self: stretch;
  flex-direction: column;
}