.rating_stats_container {
  border: 1px solid #313141;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0px;
  border-radius: 8px;
}

.rating_stats_card {
  display: flex;
  flex: 1;
  border-right: 1px solid #313141;
  flex-direction: column;
  align-items: center;
}

.rating_stats_card_title {
  font-size: 16px;
  color: white;
  font-weight: 500;
}

.rating_stats_card_number {
  font-size: 30px;
  color: white;
  font-weight: 600;
}

.rating_stats_card_subtext {
  font-size: 16px;
  font-weight: 300;
  color: #b8b8bc;
  display: flex;
  flex-direction: row;
}

.rating_stats_card_subtext_icon {
  width: 13px;
  height: 14px;
}

.rating_stats_card_subtext_colored {
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}
