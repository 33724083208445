.template-manage-modal-container {
  width: 100%;
  min-height: 500px;
  height: 100%;
}

.template-manage-modal-inner-container {
  width: 100%;
  min-height: 500px;
  height: 100%;
}

.template-manage-modal-row {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0b0b1b;
  padding: 12px 40px;
  height: 40px;
  justify-content: space-between;
}

.template-manage-modal-table-header-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-right: 32px;
}
