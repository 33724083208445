.modal_locale_inner_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 88%;
  height: 100%;
  padding: 20px;
  margin-top: -20px;
}

.dropdown-locale-input {
  position: relative;
  background-color: #1B1B2C;
  border-radius: 6px;
  padding: 12px;
  margin-top: 10px;
  width: 50%;
  margin-right: 20px;
}