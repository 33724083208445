.three_dot_tooltip {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom:70px;
  border-radius: 8px ;
  right: -30px;
  width: auto;
  padding:12px;
  background-color:rgba(255,255,255,0.2);
  backdrop-filter: blur(10px);
}