
.modal_countries_selected {
  color: #84848D;
  font-size: 16px;
  font-weight: 300;
}

.modal_inner_container {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.dropdown-input-container{
  width: 70%;
}

.dropdown-input-title {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.dropdown-input {
  position: relative;
  background-color: #1B1B2C;
  border-radius: 6px;
  padding: 12px;
  margin-top: 10px;
  width: 100%;
  margin-right: 20px;
}

.dropdown-input-inactive {
  background-color: #101022;
}

.dropdown-input-text-inactive {
  color: #313141;
}


.dropdown-input-text {
  color: #84848D;
  font-size: 16px;
  font-weight: 400;
}

.dropdown-input-item {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  height: 55px;
  min-height: 55px;
  align-items: center;
  align-self: stretch;
}