.bulk_edit_container{
  display: flex;
  width: 100%;
  align-self: stretch;
  justify-content: center;
  flex-direction: column;
}

.bulk_edit_text{
  color:#84848D;
  align-self: center;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight:300;
}

.bulk_edit_flag_container{
  display:flex;
  flex-wrap: wrap;
  flex-direction:row;
  width: 90%;
  align-self: center;
  align-items: center;
  justify-content: center;
}

.bulk_edit_content_container{
  display: flex;
  margin-top: 16px;
  margin-left: 20px;
  flex-direction: column;
  margin-right: 20px;
}

.bulk_edit_content_container_text{
  color: white;
  font-size: 18px;
}