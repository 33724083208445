.dropdown_container {
  background-color: rgba(255, 255, 255, 0.1);
  z-index: 2;
  width: 300px;
  height: 225px;
  position: absolute;
  overflow-y: scroll !important;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1.5px solid #313141;
  backdrop-filter: blur(10px);
}

.dropdown_container::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}
.dropdown_container::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.5);
}

.dropdown_add_new_app {
  display: flex;
  height: 55px;
  min-height: 55px;
  align-items: center;

  border-bottom-color: #313141;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  flex-direction: row;
}

.dropdown-inactive-add {
  opacity: 0.15;
}

.dropdown_add_new_app_icon {
  width: 25px;
  height: 25px;
  margin-left: 20px;
}

.dropdown_add_new_app_text {
  color: white;
  font-weight: 500;
  margin-left: 10px;
}

.dropdown_app_container {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  height: 55px;
  min-height: 55px;
  align-items: center;
  align-self: stretch;
}

.dropdown_app_icon {
  width: 25px;
  border-radius: 8px;
  height: 25px;
}

.dropdown_app_text {
  color: white;
  margin-left: 10px;
  font-size: 14px;
}

.dropdown_app_tick {
  width: 16px;
  height: auto;
  margin-left: 12px;
  margin-right: 12px;
}

.dot {
  width: 6px;
  height: 6px;
  border-radius: 3px;
  margin-left: 10px;
  margin-right: 4px;
}
