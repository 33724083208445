.country_flag_icon{
  width: 25px;
  height: 25px;
  object-fit: contain;
}

.country_flag_icon_container{
  width: 25px;  
  height: 25px;
}

.country_flag_tooltip{
  position:absolute;
  left:-55px;
  top:-40px;
  width:140px;
  line-clamp: 1;
  background-color:rgba(255,255,255,0.2);
  padding-top:4px;
  
  border-width: 1px;
  border-color: #313141;
  border-style: solid;
  padding-bottom:4px;
  backdrop-filter: blur(10px);
  border-radius:8px;
  display:flex;
  justify-content:center;
}

.country_flag_tooltip_text{
  color:white;
  font-weight:500;
  white-space: nowrap; /* Metni satır sonu olmadan tek bir satırda tutar. */
  text-overflow: ellipsis;
  overflow: hidden;
  margin-left: 5px;
  margin-right: 5px;
}