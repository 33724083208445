

.custom_button {
  display: flex;
  justify-content: center;
  border-radius: 8px;
  padding-right: 30px;
  padding-left: 30px;
  padding-top: 8px;
  padding-bottom: 8px;
  align-items: center;
  margin-left: 18px;
}

.text{
  color: white;
  font-weight: 500;
  
}

.button_icon{
  width: 28px;
  height: 28px;
  margin-right: 8px;
}

.lds-ring {
  display: flex;
  justify-content: center;
  align-items: center;  position: relative;
  width: 24px;
  height: 24px;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  border: 2px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0.5, 0.5, 0.5) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
