.se-row-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px 40px;
  margin-top: 20px;
}

.se-row-country {
  font-size: 20px;
  font-weight: 400;
  color: white;
  width: 240px;
  padding-left: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.se-row-country-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}