.app_detail_header_container {
  width: 100%;
  height: 150px;
  background-image: url("../../assets/shadow.webp");
  background-size: cover;
  background-position-y: 0;

  display: flex;
  flex-direction: column;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #313141;
}

.app_detail_header_container_top {
  display: flex;
  flex: 2;
  padding-left: 40px;
  align-items: center;
  align-self: stretch;
}

.app_name_container {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  cursor: pointer;
}

.app_detail_container_bottom {
  display: flex;
  flex: 1;
  align-self: stretch;
}

.app_icon {
  width: 50px;
  height: 50px;
  border-radius: 8px;
}

.all_apps_container {
  background-color: rgba(255, 255, 255, 0.1);
  width: 300px;
  height: 225px;
  overflow: auto;
  position: absolute;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1.5px solid #313141;
  z-index: 999;
  backdrop-filter: blur(10px);
}

.all_apps_add_new_app {
  display: flex;
  height: 55px;
  min-height: 55px;
  align-items: center;

  border-bottom-color: #313141;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  flex-direction: row;
}

.all_apps_add_new_app_icon {
  width: 25px;
  height: 25px;
  margin-left: 20px;
}

.all_apps_add_new_app_text {
  color: white;
  font-weight: 500;
  margin-left: 10px;
}

.all_apps_app_container {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  height: 55px;
  min-height: 55px;
  align-items: center;
  align-self: stretch;
}

.all_apps_app_icon {
  width: 25px;
  border-radius: 8px;
  height: 25px;
}

.all_apps_app_text {
  color: white;
  margin-right: 8px;
  margin-left: 10px;
  font-size: 14px;
}

.all_apps_app_tick {
  width: 16px;
  object-fit: contain;
  height: 16px;
  margin-left: 12px;
  margin-right: 12px;
}

.bottom-arrow {
  border-bottom: 0px solid #6a0136;
}

.other_buttons {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  flex-direction: row;
  align-self: stretch;
}

.tab_button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 3px;
  margin-left: 40px;
}

.tab_button_text_selected {
  color: white;
  margin-left: 8px;
}

.app_detail_container_bottom_right {
  display: flex;
  flex: 1;
  align-self: stretch;
  justify-content: flex-end;
  margin-right: 40px;
}

.paste_from_metadata {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
}

.paste_from_text_metadata {
  color: white;
}

.paste_icon_metadata {
  width: 20px;
  margin-right: 10px;
  height: 20px;
}

.tab_button_text {
  color: #84848d;
  margin-left: 8px;
}

.tab_button_border {
  margin-bottom: 0px;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: #426bfd;
}

.tab_button_icon {
  width: 20px;
  height: 20px;
  fill: green;
  object-fit: contain;
}

.tab_button_icon_selected {
  fill: red;
}

.app_name {
  color: white;
  font-size: 24px;
  margin-left: 12px;
  font-weight: 500;
}
