.ratings_table_item_container {
  background-color: #101022;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
  margin-bottom: 40px;
  position: relative;
}

.ratings_table_item_container_top {
  height: 5px;
  width: 100%;
  border-radius: 8px 8px 0px 0px;
  top: 0;
  left: 0;
  position: absolute;
  background-color: #39ac5d;
}

.ratings_table_item_star {
  width: 20px;
  height: 20px;
  padding-right: 3px;
}

.ratings_table_item_title {
  font-size: 18px;
  color: white;
  font-weight: 400;
  margin-bottom: 8px;
}

.ratings_table_item_body {
  font-size: 14px;
  color: white;
  font-weight: 300;
}

.ratings_table_item_nick {
  font-size: 14px;
  font-weight: 300px;
  color: #b8b8bc;
  margin-left: 12px;
}

.ratings_table_item_bottom {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}

.ratings_table_item_bottom_card {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  padding: 4px 12px;
  font-weight: 400;
  font-size: 16px;
  color: white;
  margin-right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ratings_table_item_bottom_reply {
  border: 1px solid #426bfd;
  border-radius: 6px;
  padding: 8px 40px;
  cursor: pointer;
  font-size: 16px;
  color: #426bfd;
  background-color: rgba(66, 107, 253, 0.1);
}

.ratings_table_item_bottom_card_flag {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  object-fit: cover;
  margin-right: 8px;
}

.ratings_table_item_reply_mode_container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  border-top: 1px dashed rgba(255, 255, 255, 0.1);
  padding-top: 30px;
}

.ratings_table_item_reply_mode_title {
  font-size: 16px;
  color: white;
  font-weight: 400;
}

.ratings_table_item_reply_mode_characters {
  font-size: 14px;
  color: #84848d;
  font-weight: 300;
}

.ratings_table_item_reply_mode_textarea {
  border: 1px solid transparent;
  background-color: #1b1b2c;
  border-radius: 6px;
  height: 46px;
  font-weight: 400;
  padding-left: 12px;
  font-family: "Poppins";
  color: white;
  font-size: 14px;
  padding-top: 10px;
  margin-top: 10px;
  resize: vertical;
  min-height: 100px;
  width: 99%;
}

.ratings_table_item_reply_mode_textarea_disabled {
  background-color: #08081a;
  color: #47475e;
}

.ratings_table_item_reply_mode_textarea:focus {
  border: 1px solid white;
  outline: none;
}

.rating_table_paging_item {
  width: 40px;
  cursor: pointer;
  height: 40px;
  margin: 0px 0.5px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.rating_table_paging_item_border {
  border: 1px solid white;
}

.rating_table_paging_left_arrow {
  width: 40px;
  cursor: pointer;
  height: 40px;
  margin: 0px 0.5px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  border-radius: 6px 0px 0px 6px;
  justify-content: center;
  color: white;
}

.rating_table_paging_prev_icon {
  width: 5px;
  height: 12px;
}

.rating_table_paging_right_arrow {
  width: 40px;
  cursor: pointer;
  height: 40px;
  margin: 0px 0.5px;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  border-radius: 0px 6px 6px 0px;
  justify-content: center;
  color: white;
}

.ratings_table_item_badge {
  padding: 5px 10px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
}

.ratings_table_item_translate_button {
  color: white;
  font-size: 14px;
  border: 1px solid #426bfd;
  border-radius: 6px;
  position: relative;
  align-items: center;
  font-weight: 300;
  justify-content: flex-start;
  align-self: flex-start;
  display: flex;
  cursor: pointer;
}

.ratings_table_item_translate_language_title {
  color: white;
  padding: 0px 10px;
}

.ratings_table_item_translate_translate_text {
  background-color: #426bfd;
  border-radius: 0px 2px 2px 0px;
  padding: 4px 10px;
  font-weight: 500;
}

.ratings_table_item_translate_drop {
  width: 15px;
  height: 15px;
  margin-right: 24px;
  object-fit: contain;
}

.ratings_table_item_translate_select_drop {
  color: white;
  font-size: 14px;
  border: 1px solid white;
  border-radius: 100px;
  padding: 2px 10px;
  margin-left: 12px;
  position: relative;
  align-items: center;
  font-weight: 300;
  align-self: flex-start;
  display: flex;
  cursor: pointer;
  align-items: center;
}
