.table-row-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0b0b1b;
  padding: 12px 0px;
  height: 40px;
  justify-content: space-between;
  border-bottom: 1px solid #202030;
}

.table-row-container:hover {
  background-color: #171727;
}

.table-row-right-arrow {
  height: 15px;
  width: 7px;
  margin-right: 12px;
}

.table-row-drop-arrow {
  width: 15px;
  height: 7px;
  margin-right: 4px;
}

.table-row-item-title {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  color: white;
}

.table-sub-row-container {
  background-color: #08081a;
  border-bottom: 1px solid #202030;
}

.pricing-subs-table-sub-row-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0px;
}

.pricing-subs-table-sub-row-item-left {
  display: flex;
  flex: 3;
}

.pricing-subs-table-sub-row-item-right {
  display: flex;
  flex: 1;
}

.table-sub-row-item-title {
  color: #426bfd;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.table-sub-row-item-line {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 60px;
}

.pricing-subs-table-sub-row-item-template {
  font-weight: 400;
  color: white;
  border: 1px solid white;
  border-radius: 10px;
  cursor: pointer;
  padding: 5px 15px;
}

.table-sub-row-item-badge {
  border: 1px solid #313141;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-left: 10px;
  padding: 4px 15px;
  font-weight: 300;
  font-size: 14px;
  color: #b8b8bc;
}

.subs-page-empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 100px;
}

.subs-page-empty-container p {
  color: #5a5a67;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  max-width: 50%;
}

.subs-page-empty-container img {
  width: 400px;
  height: auto;
}
