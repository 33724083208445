.custom_input_container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px ;
  position: relative;
  width: 100%;
}

.custom_input_title {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.custom_input_input {
  border: 1px solid transparent;
  background-color: #1B1B2C;
  border-radius: 6px;
  height: 46px;
  font-weight: 400;
  font-family: "Poppins";
  color: white;
  padding-left: 12px;
  font-size: 16px;
  width: 100%;
}


.custom_input_input:focus {
  border: 1px solid white;
  outline: none;
}

.custom_textarea {
  padding-top: 10px;
  resize:vertical;
  min-height: 200px;
}

.copy-to-clickboard-container {
  position: absolute;
  right: 8px;
  padding: 6px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
  background-color: #313141;
}

.copy-to-clickboard-image {
  width: 16px;
  height: 16px;
}

.custom-input-question-container{
  position: absolute;
  right: 10px;
  display: flex;
  justify-content: center;
  border-radius: 4px;
  align-items: center;
}

.custom-input-question-image {
  width: 25px;
  height: 25px;
}

.custom_input_maxlength {
  font-size: 16px;
  color: #454554;
  font-weight: 400;
  align-self: flex-end;
  margin-top: 4px;
}

.custom_input_input_inactive {
  background-color: #101022;
  color: #454554;
}

.input-span-outer {
  display: flex;
  margin-top: 10px;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
  position: relative;
  width: 100%;
}

.input-span-flag {
  width: 25px;
  height: 25px;
  object-fit: contain;
  margin-right: 12px;
}

.duplicate_hover_container{
  position: absolute;
  left:-15px;
  bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  
  line-clamp: 1;
  background-color:rgba(255,255,255,0.2);
  padding-top:4px;
  
  border-width: 1px;
  border-color: #313141;
  border-style: solid;
  padding-bottom:4px;
  backdrop-filter: blur(10px);
  border-radius:8px;
  display:flex;
  justify-content:center;
}