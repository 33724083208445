.App {
  font-family: "Poppins";
  min-width: 1080px;
}

body {
  background-color: #08081a;
}

.mt-flex-row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.cursor {
  cursor: pointer;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a:link {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
  text-decoration: none;
}

a:hover {
  color: inherit;
  text-decoration: none;
}

a:active {
  color: inherit;
  text-decoration: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate__animated.animate__fadeIn {
  --animate-duration: 300ms;
}
