.modal_container {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(255, 255, 255, 0.05);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal_content_big {
  width: 80vw;
  border-radius: 8px;
  max-height: 80vh;
  display: flex;
  background-color: #08081a;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  border-width: 1px;
  border-color: #313141;
  border-style: solid;
  flex-direction: column;
}

.modal_content_small {
  flex-direction: column;
  display: flex;
  width: 500px;
  border-radius: 8px;
  height: 280px;
  display: flex;
  background-color: #08081a;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 5px 15px;
  border-width: 1px;
  border-color: #313141;
  border-style: solid;
  display: flex;
}

.modal_content_header_title {
  color: white;
  font-weight: 500;
}

.modal_content_header {
  height: 50px;
  min-height: 50px;
  display: flex;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: #313141;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.modal_content_header_cross {
  width: 15px;
  object-fit: contain;
  height: 15px;
}

.modal_footer {
  display: flex;
  align-self: stretch;
  height: 100%;
}

.modal_sure_container {
  display: flex;
  flex: 1;
  align-items: center;
  align-self: stretch;
  flex-direction: column;
}

.modal_content_header_cross_container {
  position: absolute;
  right: 0px;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal_sure_icon {
  width: 40px;
  height: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
  object-fit: contain;
}

.modal_white_border {
  border-radius: 8px;
  border-width: 1px;
  border-color: white;
  border-style: solid;
}

.modal_sure_text {
  color: white;
  font-weight: 500;
  margin-left: 30px;
  font-size: 16px;
  text-align: center;
  margin-right: 30px;
}

.modal_sure_button {
  width: 180px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 8px;
  background-color: yellow;
}

.modal_sure_button_text {
  padding-top: 6px;
  justify-content: center;
  display: flex;
  font-weight: 500;
  justify-content: center;
  color: white;
  padding-bottom: 6px;
}

.modal_sure_button_container {
  display: flex;
  flex: 1;
  align-self: stretch;
  align-items: flex-end;
  margin-bottom: 30px;
  justify-content: center;
}
