.rt_country_button {
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: auto;
  position: relative;
  margin-left: 16px;
}

.rt_circle_flag {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 20px;
}

.rt_country_name {
  color: white;
  margin-left: 10px;
  margin-right: 10px;
}

.rt_country_bottom_arrow {
  width: 12px;
  height: auto;
  object-fit: contain;
}
