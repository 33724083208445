.selector-inactive {
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background-color: #1B1B2C;
  border: 1px solid #313141;
}

.selector-active {
  width: 24px;
  height: 24px;
  display: block;
  background-image: url("../../assets/selected.webp");
  background-size: contain;
  border-radius: 5px;
  border: 1px solid #313141;
}
