.login_container{
 width: 500px;
 display: flex;
 flex-direction: column;
 background-color: #08081A;
 border-radius: 8px;
 padding-left: 20px;
 padding-right: 20px;
 z-index: 2;
 border-width: 1px;
border-style: solid;
border-color: #313141;
}

.login_text{
  color:white;
  font-weight: 500;
  font-size: 36px;
  align-self: center;
  margin-top: 30px;
}

.login-terms-text {
 z-index: 2;
  color:rgba(255, 255, 255, .3);
  font-weight: 300;
  font-size: 14px;
  margin-top: 12px;
  text-decoration: none !important; 
}

.login-bottom {
  position: absolute;
  bottom: 20px;
  z-index: 3;
}

.login-no-account {
  font-weight: 400;
  font-size: 16px;
  color: #84848D;
}