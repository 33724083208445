.template-page-container {
  display: flex;
  overflow: scroll;
  flex-direction: column;
  margin-bottom: 120px;
}

.template-page-name {
  color: white;
  font-size: 20px;
  margin-left: 40px;
  margin-top: 30px;
  font-weight: 400;
}

.template-page-empty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.template-page-empty-container p {
  color: #5a5a67;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  max-width: 50%;
}

.template-page-empty-button-text {
  font-size: 18px;
  color: white;
  font-weight: 400;
  margin-top: 8px;
  display: block;
  text-align: center;
  margin-bottom: 80px;
}

.template-page-empty-button {
  cursor: pointer;
  width: 80px !important;
  height: 80px !important;
}

.template-page-empty-container img {
  width: 440px;
  height: 440px;
}

.template-add-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  width: 400px;
  height: 600px;
  cursor: pointer;
  color: white;
  font-weight: 500;
  font-size: 20px;
}

.template-add-board-icon {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.template-card-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #141426;
  padding: 10px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}

.template-card-header-countries-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
}

.template-card-header-countries {
  color: white;
  font-size: 16px;
  font-weight: 400;
}

.template-card-header-manage {
  color: #426bfd;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.template-card-header-name-input {
  background-color: #1b1b2c;
  border: none;
  padding: 10px 15px;
  font-weight: 500;
  color: white;
  font-family: "Poppins";
  font-size: 20px;
  width: 160px;
  text-align: center;
  border-radius: 6px;
  outline: none;
}

.template-card-header-name-input:focus {
  background-color: #1b1b2c;
  outline: none;
}

.template-card-header-discount-input {
  background-color: #1b1b2c;
  border: none;
  padding: 10px 15px;
  font-weight: 400;
  color: white;
  height: 24px;
  font-family: "Poppins";
  font-size: 18px;
  border-radius: 6px;
  width: 30px;
  text-align: end;
  margin-right: 8px;
  outline: none;
}

.template-card-header-discount-input:focus {
  background-color: #1b1b2c;
  outline: none;
}

.template-card-header-icon-container {
  background-color: #313141;
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 10px;
  border-radius: 4px;
}

.template-card-header-top {
  position: relative;
}

.template-card-header-empty {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 240px;
  right: 0;
  left: 0;
}

.template-card-header-empty-text {
  display: flexbox;
  max-width: 300px;
  text-align: center;
  -webkit-flex-wrap: wrap;
  text-wrap: wrap;
  flex-wrap: wrap;
  word-wrap: break-word;
  color: #5a5a67;
  font-size: 18px;
  font-weight: 400;
}

.template-card-header-empty-manage {
  color: #426bfd;
  border: 1px dashed #47475e;
  padding: 4px 10px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  font-size: 18px;
  font-weight: 400;
}

.template-card-header-icon {
  width: 16px;
  object-fit: contain;
  height: 16px;
}

.react-kanban-column-adder-button {
  background-color: red;
}

.template-card-discount-title {
  color: white;
  font-size: 18px;
  font-weight: 400;
}

.template-card-discount-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  margin-top: 10px;
}

.template-card-discount-rate {
  color: white;
  font-weight: 500;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.template_item_bottom_card_flag {
  width: 20px;
  height: 20px;
  border-radius: 100px;
  object-fit: cover;
  margin-right: 8px;
}

.react-kanban-board {
  padding: 0px;
}

.react-kanban-board > div {
  padding: 10px 10px;
  padding-bottom: 30px;
}

.react-kanban-column {
  display: flex;
  width: 400px;
  height: 600px;
  overflow: hidden !important;
  border-radius: 12px !important;
  padding: 0px !important;
  margin: 20px;
  background-color: #101022 !important;
  border: 1px solid #202030 !important;
}

[data-rbd-droppable-context-id] {
  overflow: scroll !important;
  height: 400px !important;
  padding-bottom: 100px;
}

[data-rbd-droppable-id="board-droppable"] {
  overflow-y: hidden !important;
  overflow-x: scroll;
  height: 600px !important;
}

.react-kanban-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 360px;
  max-width: 360px;
  background-color: #282838;
  margin: 0px 20px;
  margin-bottom: 15px;
  color: white;
  border-radius: 6px !important;
}

[data-rbd-draggable-id="column-draggable-add_column"] {
  border: 1px dashed #47475e !important;
}

.template-kanban-card-drag {
  width: 16px;
  height: 16px;
}
