.trial_end_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.trial_end_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.trial_end_header_container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.trial_end_icon {
  width: 200px;
  height: 200px;
  object-fit: contain;
}

.trial_end_plan_container {
  margin-left: 20%;
  margin-right: 20%;
  background-color: #08081a;
  flex-direction: column;
  border-width: 1px;
  display: flex;
  flex: 1;
  align-items: center;
  border-color: #313141;
  border-style: solid;
  border-radius: 8px;
}

.trial_end_plan_pick_plan {
  color: white;
  margin-top: 16px;
  font-weight: 500;
  font-size: 32px;
}

.trial_end_plan_pick_plan_desc {
  color: white;
  margin-top: 10px;
  font-weight: 200;
  font-size: 16px;
}

.custom_toggle_trial_end {
  display: flex;
  margin-top: 20px;
  flex-direction: row;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.05);
  overflow: hidden;
}

.selected_toggle_trial_end {
  background-color: white;
  overflow: hidden;
  display: flex;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 130px;
}

.unselected_toggle_trial_end {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 130px;
}

.unselected_toggle_trial_end_text {
  color: white;
  font-size: 18px;
}

.selected_toggle_trial_end_text {
  color: black;
  font-size: 18px;
}
