.inapp-table-row-container {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #0b0b1b;
  padding: 12px 0px;
  height: 40px;
  justify-content: space-between;
  border-bottom: 1px solid #202030;
}

.inapp-table-row-container:hover {
  background-color: #171727;
}

.inapp-table-row-right-arrow {
  height: 15px;
  width: 7px;
  margin-right: 12px;
}

.inapp-table-row-item-title {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 400;
  position: relative;
  color: white;
}

.inapp-table-sub-row-container {
  background-color: #08081a;
  border-bottom: 1px solid #202030;
}

.inapp-table-sub-row-item-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 0px;
  padding-left: 120px;
}

.inapp-table-sub-row-item-title {
  color: #426bfd;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

.inapp-table-sub-row-item-line {
  width: 14px;
  height: 14px;
  margin-right: 10px;
  margin-bottom: 10px;
}

.inapp-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  margin-top: 25vh;
  flex: 1;
}

.coming-soon {
  color: #5a5a67;
  font-size: 18px;
  font-weight: 500;
}

.coming-soon-image {
  width: 120px;
  height: auto;
}
