.container {
  text-align: center;
}


.duplicate_container{
  display: flex;
  margin-left: 0px;
  top: 0px;
  left:0px;
  right: 0px;
  margin-right: 0px;
  padding-top: 12px;
  align-items: center;
  padding-bottom: 12px;
  border-radius: 8px;
  background-color: rgba(255,212,58,0.1);
}

.warning_icon{
  width: 22px;
  margin-left: 8px;
  height: 22px;
  object-fit: contain;
}

.duplicate_text{
  color: #FFD43A; 
  margin-left: 12px;
  font-size: 14px;
}

.app_detail_container{
  position: relative;
  
}